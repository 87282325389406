import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from '../components/layout/layout'
import Meta from '../components/meta/meta'

interface Props {
  location: Location
}

const TraditionalVC: React.FC<Props> = ({ location }: Props) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="To tradtional VC or&nbsp;not..." />
      <div className="section shape6-16 d-flex container-fluid">
        <div className="container-xl py-10 mb-5">
          <div className="container-sm m-0">
            <h2>To Traditional VC or not...?</h2>
            <p>
              We didn’t start this thing to hate on the existence of traditional
              Venture Capital — we’ve raised lots of Venture Capital for our
              previous companies, we are Limited Partners (investors) in
              traditional Venture Capital funds, many impactful innovations and
              wonderful companies exist in no small part because of traditional
              Venture Capital… but implicit in the fact that it is a great fit
              for some companies is the fact that it is{' '}
              <b>not a fit for many others</b>.
            </p>
          </div>
        </div>
      </div>
      <div className="section shape13 d-flex align-items-center bg-transparent-dark text-light container-fluid">
        <div className="container-xl py-10">
          <div className="m-0">
            <h3 className="container-md left-center mb-5">
              Traditional Venture Capital is designed for&nbsp;companies:
            </h3>
            <div className="row">
              <div className="col-sm-12 col-md-4 mb-5">
                <p className="mt-0 mb-3">
                  <b className="font-size-lg">
                    1. Taking on big, complicated, novel technical challenges
                  </b>
                </p>
                <p className="mt-0 mb-3">
                  If you're building{' '}
                  <a
                    className="fancy-link"
                    href="https://www.youtube.com/watch?v=HVqWEoyiaBA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    spaceships
                  </a>
                  ,{' '}
                  <a
                    className="fancy-link"
                    href="https://www.wired.com/2016/10/ubers-self-driving-truck-makes-first-delivery-50000-beers/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    driverless trucks
                  </a>
                  ,{' '}
                  <a
                    className="fancy-link"
                    href="https://www.youtube.com/watch?v=U2lC7k_eyN0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    supersonic jets
                  </a>
                  ,{' '}
                  <a
                    className="fancy-link"
                    href="https://www.youtube.com/watch?v=fn3KWM1kuAw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    dancing robots
                  </a>
                  ,{' '}
                  <a
                    className="fancy-link"
                    href="https://www.entrepreneur.com/article/360574"
                    target="_blank"
                    rel="noreferrer"
                  >
                    platforms to cure disease
                  </a>
                  , etc… good on you.
                </p>
                <p>
                  {' '}
                  You are building some cool shit, which will have profound
                  impact on our world… and you are going to need to invest a lot
                  of money working through exceptionally difficult technical
                  problems.
                </p>
              </div>
              <div className="col-sm-12 col-md-4 mb-5">
                <p className="mt-0 mb-3">
                  <b className="font-size-lg">
                    2. Competing in huge winner-take-all markets with big
                    network effects
                  </b>
                </p>
                <p className="mt-0 mb-3">
                  Especially where it helps to delay monetization in favor of
                  audience building. Facebook, Twitter, Slack, Uber, Doordash,
                  Instacart and many other exceptionally successful companies
                  immediately come to mind.
                </p>
                <p className="m-0">
                  No shame in the game here either — the rewards of winning when
                  building this type of business are huge.
                </p>
              </div>
              <div className="col-sm-12 col-md-4 mb-5">
                <p className="m-0">
                  <b className="font-size-lg">
                    3. Committed to racing to build billions of dollars in
                    shareholder value… or bust
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section shape18 bg-light minHeight bg4 d-flex align-items-center container-fluid">
        <div className="container-xl py-10">
          <div className="container-xs m-0">
            <h3>Misalignment</h3>
            <p>
              The investing strategies of traditional VC funds are built around
              the reality that the returns they earn from their investments will
              be heavily driven by the very, very small percentage of
              exceptionally valuable companies. This is great if the company you
              want to build fits nicely into one or more of the three types
              outlined above… however, if it doesn’t, we think the resulting
              misalignment is <b>less than ideal</b> for the founders, their
              teams, and ultimately their customers.
            </p>
          </div>
        </div>
      </div>
      <div className="section shape8 bg-light minHeight d-flex bg-dark text-light align-items-center container-fluid">
        <div className="container-xl py-10">
          <div className="container-xs">
            <h3>Build the company you want to build</h3>
            <p>
              Our motivation for building Sodality Ventures is to offer you a
              high quality alternative option — one which attempts to positively
              address the elements which draw you to traditional Venture
              Capital, but which is designed intentionally to align with the
              type of company you want to build.
            </p>
            <div>
              <a
                className="btn btn-lg btn-primary"
                href="/work-with-us/"
                role="button"
              >
                Apply
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TraditionalVC
